import React, { useState } from 'react';
import { Navigate, useRouteLoaderData } from 'react-router-dom';

import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';
import Welcome from '../../components/Welcome';
import DocumentDetail from '../../components/DocumentDetail';
import PaymentDetail from '../../components/PaymentDetail';
import ReferenceDesk from '../../components/ReferenceDesk';
import MeterDetail from '../../components/MeterDetail';
import AmountsSummary from '../../components/AmountsSummary';
import { JsonVeritasIdrico } from '../models/jsonVeritas';

const Document = (props: { loader: string }) => {
	const json = useRouteLoaderData(props.loader) as JsonVeritasIdrico;

	const [showDetails, setShowDetails] = useState<boolean>(false);
	if (!json) return <Navigate to='/veritas/not-found' />;

	const toggleDetails = async () => {
		agent.Tracking.track(
			`${Static.toggleDetail} ${Static.fromDocumentComponent}`
		);

		setShowDetails((prev) => !prev);
	};

	return (
		<div id='document'>
			{/* <ngx-spinner bdColor="rgba(51,51,51,0.4)"></ngx-spinner> */}
			<div className='row pt-4 pb-4 app-box-one-idrico'>
				<div className='col-12 col-md-6 col-xl-7'>
					<div className='m-2 m-md-5'>
						<Welcome
							welcome={json.Customer.Welcome}
							cliente={json.Customer.Cliente}
							codiceFiscale={json.Customer.CodiceFiscale}
							codiceUtente={json.Customer.CodiceUtente}
							numeroContratto={
								json.Fatture[0].DettaglioContratto.NumeroContratto
							}
							indirizzo={json.Fatture[0].DettaglioContratto.Indirizzo}
							tipologiaTariffa={
								json.Fatture[0].DettaglioContratto.TipologiaTariffa
							}
						/>
					</div>
				</div>
				<div className='col-12 col-md-6 col-xl-5'>
					<div className='m-2 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
						<DocumentDetail
							importoTotaleContratto={
								json.Fatture[0].RiepilogoDocumento.ImportoTotaleContratto
							}
							scadenzaBolletta={json.Fatture[0].ScadenzaBolletta}
							documentoN={json.Fatture[0].DocumentoN}
							documentoPeriodo={json.Fatture[0].DocumentoPeriodo}
							consumoFatturato={
								json.Fatture[0].DettaglioContratto.ConsumoFatturato
							}
							datiBolletta={json.Fatture[0].DatiBolletta}
							isSingleDocument={true}
						/>
					</div>
				</div>
			</div>
			<div className='row pt-4 pb-4'>
				<div className='col-12 col-md-6'>
					<div className='m-2 me-md-1 mt-md-5 ms-md-5 mb-md-5'>
						<PaymentDetail payment={json.Pagamenti} />
					</div>
					<div className='m-2 d-none d-sm-block me-md-1 mt-md-5 ms-md-5 mb-md-5'>
						<ReferenceDesk sportello={json.Fatture[0].Sportello} />
					</div>
				</div>
				<div className='col-12 col-md-6'>
					<div className='m-2 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
						<MeterDetail
							matricolaContatore={
								json.Fatture[0].DettaglioContratto.Contatore.MatricolaContatore
							}
							ultimaLettura={
								json.Fatture[0].DettaglioContratto.Contatore.UltimaLettura
							}
							periodoLettura={
								json.Fatture[0].DettaglioContratto.Contatore.PeriodoLettura
							}
							documentoN={json.Fatture[0].DocumentoN}
						/>
					</div>
				</div>
			</div>
			<div id='detail' className='row pt-4 pb-4 app-box-two-idrico'>
				<div className='col-12 col-md-6'>
					<div className='m-2 me-md-1 mt-md-5 ms-md-5 mb-md-5'>
						<div className='card ps-5 pe-5 pt-3 pb-3 mb-3 d-block d-sm-none'>
							<div className='app-toggle-show' onClick={toggleDetails}>
								<h4 className='mb-0'>ALTRI DETTAGLI</h4>
								<i
									className={
										showDetails ? 'fa fa-angle-up' : 'fa fa-angle-down'
									}
									style={{ fontSize: '1.5rem' }}
								></i>
							</div>
						</div>

						{showDetails && (
							<div className='card p-5 d-block d-sm-none'>
								{json.Fatture[0].TipoFatturazione && (
									<div className=' app-p-bigger'>
										{json.Fatture[0].TipoFatturazione.Etichetta}:{' '}
										<span className='app-bold'>
											{json.Fatture[0].TipoFatturazione.Valore}
										</span>
									</div>
								)}

								{json.Fatture[0].DettaglioContratto.Altro.NucleiDom && (
									<div className='app-p-bigger mt-4'>
										{
											json.Fatture[0].DettaglioContratto.Altro.NucleiDom
												.Etichetta
										}
										:{' '}
										<span className='app-bold'>
											{
												json.Fatture[0].DettaglioContratto.Altro.NucleiDom
													.Valore
											}
										</span>
									</div>
								)}

								{json.Fatture[0].DettaglioContratto.Altro.NucleiCom && (
									<div className='app-p-bigger mt-4'>
										{
											json.Fatture[0].DettaglioContratto.Altro.NucleiCom
												.Etichetta
										}
										:{' '}
										<span className='app-bold'>
											{
												json.Fatture[0].DettaglioContratto.Altro.NucleiCom
													.Valore
											}
										</span>
									</div>
								)}

								{json.Fatture[0].DettaglioContratto.Altro.NucleiSecondeCase && (
									<div className='app-p-bigger mt-4'>
										{
											json.Fatture[0].DettaglioContratto.Altro.NucleiSecondeCase
												.Etichetta
										}
										:{' '}
										<span className='app-bold'>
											{
												json.Fatture[0].DettaglioContratto.Altro
													.NucleiSecondeCase.Valore
											}
										</span>
									</div>
								)}

								{json.Fatture[0].DettaglioContratto.Altro
									.AllacciamentoFognature && (
									<div className='app-p-bigger mt-4'>
										{
											json.Fatture[0].DettaglioContratto.Altro
												.AllacciamentoFognature.Etichetta
										}
										:{' '}
										<span className='app-bold'>
											{
												json.Fatture[0].DettaglioContratto.Altro
													.AllacciamentoFognature.Valore
											}
										</span>
									</div>
								)}

								{json.Fatture[0].DettaglioContratto.Altro.Componenti &&
									json.Fatture[0].DettaglioContratto.Altro.Componenti.length >
										0 && (
										<div>
											{json.Fatture[0].DettaglioContratto.Altro.Componenti.map(
												(item, index) => {
													return (
														<div key={index} className='app-p-bigger mt-4'>
															{item.Etichetta}:{' '}
															<span className='app-bold'>{item.Valore}</span>
														</div>
													);
												}
											)}
										</div>
									)}

								{json.Fatture[0].DettaglioContratto.Altro
									.ImpiantoDepurazione && (
									<div className='app-p-bigger mt-4'>
										{
											json.Fatture[0].DettaglioContratto.Altro
												.ImpiantoDepurazione.Etichetta
										}
										:{' '}
										<span className='app-bold'>
											{
												json.Fatture[0].DettaglioContratto.Altro
													.ImpiantoDepurazione.Valore
											}
										</span>
									</div>
								)}

								{json.Fatture[0].DettaglioContratto.Altro
									.DepositoCauzionale && (
									<div className='app-p-bigger mt-4'>
										{
											json.Fatture[0].DettaglioContratto.Altro
												.DepositoCauzionale.Etichetta
										}
										:{' '}
										<span className='app-bold'>
											{
												json.Fatture[0].DettaglioContratto.Altro
													.DepositoCauzionale.Valore
											}
										</span>
									</div>
								)}

								{json.Fatture[0].DettaglioContratto.Altro
									.AdesioneFondoPerdite && (
									<div className='app-info-div mt-4'>
										<img
											alt=''
											className='app-info-icon'
											src='/images/veritas/invoice//SecurityIcon.svg'
										/>
										<div className='ms-2'>
											<span className='app-bold'>
												{' '}
												{
													json.Fatture[0].DettaglioContratto.Altro
														.AdesioneFondoPerdite.Etichetta
												}
											</span>
											<br />
											<span className='app-p-bigger'>
												{
													json.Fatture[0].DettaglioContratto.Altro
														.AdesioneFondoPerdite.Valore
												}
											</span>
										</div>
									</div>
								)}
							</div>
						)}

						<div className='card p-5 d-none d-sm-block'>
							<h4>ALTRI DETTAGLI</h4>
							{json.Fatture[0].TipoFatturazione && (
								<div className=' app-p-bigger mt-4'>
									{json.Fatture[0].TipoFatturazione.Etichetta}:{' '}
									<span className='app-bold'>
										{json.Fatture[0].TipoFatturazione.Valore}
									</span>
								</div>
							)}

							{json.Fatture[0].DettaglioContratto.Altro.NucleiDom && (
								<div className='app-p-bigger mt-4'>
									{json.Fatture[0].DettaglioContratto.Altro.NucleiDom.Etichetta}
									:{' '}
									<span className='app-bold'>
										{json.Fatture[0].DettaglioContratto.Altro.NucleiDom.Valore}
									</span>
								</div>
							)}

							{json.Fatture[0].DettaglioContratto.Altro.NucleiCom && (
								<div className='app-p-bigger mt-4'>
									{json.Fatture[0].DettaglioContratto.Altro.NucleiCom.Etichetta}
									:{' '}
									<span className='app-bold'>
										{json.Fatture[0].DettaglioContratto.Altro.NucleiCom.Valore}
									</span>
								</div>
							)}

							{json.Fatture[0].DettaglioContratto.Altro.NucleiSecondeCase && (
								<div className='app-p-bigger mt-4'>
									{
										json.Fatture[0].DettaglioContratto.Altro.NucleiSecondeCase
											.Etichetta
									}
									:{' '}
									<span className='app-bold'>
										{
											json.Fatture[0].DettaglioContratto.Altro.NucleiSecondeCase
												.Valore
										}
									</span>
								</div>
							)}

							{json.Fatture[0].DettaglioContratto.Altro
								.AllacciamentoFognature && (
								<div className='app-p-bigger mt-4'>
									{
										json.Fatture[0].DettaglioContratto.Altro
											.AllacciamentoFognature.Etichetta
									}
									:{' '}
									<span className='app-bold'>
										{
											json.Fatture[0].DettaglioContratto.Altro
												.AllacciamentoFognature.Valore
										}
									</span>
								</div>
							)}
							{json.Fatture[0].DettaglioContratto.Altro.Componenti &&
								json.Fatture[0].DettaglioContratto.Altro.Componenti.length >
									0 && (
									<div>
										{json.Fatture[0].DettaglioContratto.Altro.Componenti.map(
											(item, index) => {
												return (
													<div key={index} className='app-p-bigger mt-4'>
														{item.Etichetta}:{' '}
														<span className='app-bold'>{item.Valore}</span>
													</div>
												);
											}
										)}
									</div>
								)}

							{json.Fatture[0].DettaglioContratto.Altro.ImpiantoDepurazione && (
								<div className='app-p-bigger mt-4'>
									{
										json.Fatture[0].DettaglioContratto.Altro.ImpiantoDepurazione
											.Etichetta
									}
									:{' '}
									<span className='app-bold'>
										{
											json.Fatture[0].DettaglioContratto.Altro
												.ImpiantoDepurazione.Valore
										}
									</span>
								</div>
							)}

							{json.Fatture[0].DettaglioContratto.Altro.DepositoCauzionale && (
								<div className='app-p-bigger mt-4'>
									{
										json.Fatture[0].DettaglioContratto.Altro.DepositoCauzionale
											.Etichetta
									}
									:{' '}
									<span className='app-bold'>
										{
											json.Fatture[0].DettaglioContratto.Altro
												.DepositoCauzionale.Valore
										}
									</span>
								</div>
							)}

							{json.Fatture[0].DettaglioContratto.Altro
								.AdesioneFondoPerdite && (
								<div className='app-info-div mt-4'>
									<img
										alt=''
										className='app-info-icon'
										src='/images/veritas/invoice//SecurityIcon.svg'
									/>
									<div className='ms-2'>
										<span className='app-bold'>
											{' '}
											{
												json.Fatture[0].DettaglioContratto.Altro
													.AdesioneFondoPerdite.Etichetta
											}
										</span>
										<br />
										<span className='app-p-bigger'>
											{
												json.Fatture[0].DettaglioContratto.Altro
													.AdesioneFondoPerdite.Valore
											}
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='col-12 col-md-6'>
					<div className='m-2 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
						<AmountsSummary
							importoTotaleContratto={
								json.Fatture[0].RiepilogoDocumento.ImportoTotaleContratto
							}
							dettaglioImporti={json.Fatture[0].DettaglioImporti}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Document;

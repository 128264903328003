import { useState } from 'react';
import {
	Navigate,
	useLocation,
	useNavigate,
	useRouteLoaderData,
} from 'react-router-dom';

import { JsonVeritasIdrico } from '../models/jsonVeritas';
import agent from '../../../../../api/agent';
import { Static } from '../../../../../static';
import DocumentDetail from '../../components/DocumentDetail';
import AmountsSummary from '../../components/AmountsSummary';
import MeterDetail from '../../components/MeterDetail';

const Invoice = (props: { loader: string }) => {
	const navigate = useNavigate();

	const json = useRouteLoaderData(props.loader) as JsonVeritasIdrico;

	const { search } = useLocation();
	const [showDetails, setShowDetails] = useState(false);

	if (!json) return <Navigate to='/not-found' />;
	const key = new URLSearchParams(search).get('key');

	const fattura = key
		? json.Fatture.find((elem) => elem.key === key) || json.Fatture[0]
		: json.Fatture[0];

	const goBack = async () => {
		agent.Tracking.track(
			`${Static.goBack} ${Static.fromInvoiceComponent} ${key}`
		);
		navigate(-1);
	};

	const toggleDetails = async () => {
		agent.Tracking.track(
			`${Static.toggleDetail} ${Static.fromInvoiceComponent} ${key}`
		);
		setShowDetails((prev) => !prev);
	};

	return (
		<div id='invoice'>
			<div className='pt-4 pb-4 app-box-one-idrico-darker'>
				<div className='row'>
					<div className='app-div-go-back mt-3 ms-5' onClick={goBack}>
						<img
							className='app-icon-arrow'
							alt=''
							src='/images/veritas/invoice//ArrowSxIcon.svg'
						/>
						Torna
					</div>
				</div>
				<div className='row'>
					<div className='col-12 col-md-6'>
						<div className='m2 me-md-1 mt-md-5 ms-md-5 mb-md-5'>
							<DocumentDetail
								importoTotaleContratto={
									fattura.RiepilogoDocumento.ImportoTotaleContratto
								}
								scadenzaBolletta={fattura.ScadenzaBolletta}
								documentoN={fattura.DocumentoN}
								documentoPeriodo={fattura.DocumentoPeriodo}
								consumoFatturato={fattura.DettaglioContratto.ConsumoFatturato}
								datiBolletta={fattura.DatiBolletta}
								isSingleDocument={false}
							/>
						</div>
					</div>
					<div className='col-12 col-md-6'>
						<div className='m2 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
							<AmountsSummary
								importoTotaleContratto={
									fattura.RiepilogoDocumento.ImportoTotaleContratto
								}
								dettaglioImporti={fattura.DettaglioImporti}
							/>
						</div>
					</div>
				</div>
			</div>
			<div id='detail' className='row pt-4'>
				<div className='col-12 col-md-6'>
					<div className='mb-4 me-md-1 mt-md-5 ms-md-5 mb-md-5'>
						<div className='card app-card-with-background ps-5 pe-5 pt-3 pb-3 mb-3 d-block d-sm-none'>
							<div className='app-toggle-show' onClick={toggleDetails}>
								<h4 className='mb-0'>ALTRI DETTAGLI</h4>
								<i
									className={
										showDetails ? 'fa fa-angle-up' : 'fa fa-angle-down'
									}
									style={{ fontSize: '1.5rem' }}
								></i>
							</div>
						</div>
						{showDetails && (
							<div className='card app-card-with-background p-5 d-block d-sm-none'>
								<div>
									{fattura.DettaglioContratto.NumeroContratto.Etichetta}
									<p className='app-bold'>
										{fattura.DettaglioContratto.NumeroContratto.Valore}
									</p>
								</div>
								<div>
									{fattura.TipoFatturazione.Etichetta}
									<p className='app-bold'>{fattura.TipoFatturazione.Valore}</p>
								</div>
								<div>
									{fattura.DettaglioContratto.Indirizzo.Etichetta}
									<p className='app-bold'>
										{fattura.DettaglioContratto.Indirizzo.Valore}
									</p>
								</div>
								<div>
									{fattura.DettaglioContratto.TipologiaTariffa.Etichetta}
									<p className='app-bold'>
										{fattura.DettaglioContratto.TipologiaTariffa.Valore}
									</p>
								</div>
								{fattura.DettaglioContratto.Altro.NucleiDom && (
									<div>
										{fattura.DettaglioContratto.Altro.NucleiDom.Etichetta}
										<p className='app-bold'>
											{fattura.DettaglioContratto.Altro.NucleiDom.Valore}
										</p>
									</div>
								)}
								{fattura.DettaglioContratto.Altro.NucleiCom && (
									<div>
										{fattura.DettaglioContratto.Altro.NucleiCom.Etichetta}
										<p className='app-bold'>
											{fattura.DettaglioContratto.Altro.NucleiCom.Valore}
										</p>
									</div>
								)}
								{fattura.DettaglioContratto.Altro.NucleiSecondeCase && (
									<div>
										{
											fattura.DettaglioContratto.Altro.NucleiSecondeCase
												.Etichetta
										}
										<p className='app-bold'>
											{
												fattura.DettaglioContratto.Altro.NucleiSecondeCase
													.Valore
											}
										</p>
									</div>
								)}

								{fattura.DettaglioContratto.Altro.AllacciamentoFognature && (
									<div>
										{
											fattura.DettaglioContratto.Altro.AllacciamentoFognature
												.Etichetta
										}
										<p className='app-bold'>
											{
												fattura.DettaglioContratto.Altro.AllacciamentoFognature
													.Valore
											}
										</p>
									</div>
								)}
								{fattura.DettaglioContratto.Altro.Componenti &&
									fattura.DettaglioContratto.Altro.Componenti.length > 0 && (
										<div>
											{fattura.DettaglioContratto.Altro.Componenti.map(
												(item, index) => {
													return (
														<div key={index}>
															{item.Etichetta}
															<p className='app-bold'>{item.Valore}</p>
														</div>
													);
												}
											)}
										</div>
									)}

								{fattura.DettaglioContratto.Altro.ImpiantoDepurazione && (
									<div>
										{
											fattura.DettaglioContratto.Altro.ImpiantoDepurazione
												.Etichetta
										}
										<p className='app-bold'>
											{
												fattura.DettaglioContratto.Altro.ImpiantoDepurazione
													.Valore
											}
										</p>
									</div>
								)}

								{fattura.DettaglioContratto.Altro.DepositoCauzionale && (
									<div>
										{
											fattura.DettaglioContratto.Altro.DepositoCauzionale
												.Etichetta
										}
										<p className='app-bold'>
											{
												fattura.DettaglioContratto.Altro.DepositoCauzionale
													.Valore
											}
										</p>
									</div>
								)}

								{fattura.DettaglioContratto.Altro.AdesioneFondoPerdite && (
									<div>
										{
											fattura.DettaglioContratto.Altro.AdesioneFondoPerdite
												.Etichetta
										}
										<p className='app-bold'>
											{
												fattura.DettaglioContratto.Altro.AdesioneFondoPerdite
													.Valore
											}
										</p>
									</div>
								)}
							</div>
						)}
						<div className='card app-card-with-background p-5 d-none d-sm-block'>
							<h4>ALTRI DETTAGLI</h4>
							<div className='mt-4'>
								{fattura.DettaglioContratto.NumeroContratto.Etichetta}
								<p className='app-bold'>
									{fattura.DettaglioContratto.NumeroContratto.Valore}
								</p>
							</div>
							<div>
								{fattura.TipoFatturazione.Etichetta}
								<p className='app-bold'>{fattura.TipoFatturazione.Valore}</p>
							</div>
							<div>
								{fattura.DettaglioContratto.Indirizzo.Etichetta}
								<p className='app-bold'>
									{fattura.DettaglioContratto.Indirizzo.Valore}
								</p>
							</div>
							<div>
								{fattura.DettaglioContratto.TipologiaTariffa.Etichetta}
								<p className='app-bold'>
									{fattura.DettaglioContratto.TipologiaTariffa.Valore}
								</p>
							</div>
							{fattura.DettaglioContratto.Altro.NucleiDom && (
								<div>
									{fattura.DettaglioContratto.Altro.NucleiDom.Etichetta}
									<p className='app-bold'>
										{fattura.DettaglioContratto.Altro.NucleiDom.Valore}
									</p>
								</div>
							)}
							{fattura.DettaglioContratto.Altro.NucleiCom && (
								<div>
									{fattura.DettaglioContratto.Altro.NucleiCom.Etichetta}
									<p className='app-bold'>
										{fattura.DettaglioContratto.Altro.NucleiCom.Valore}
									</p>
								</div>
							)}
							{fattura.DettaglioContratto.Altro.NucleiSecondeCase && (
								<div>
									{fattura.DettaglioContratto.Altro.NucleiSecondeCase.Etichetta}
									<p className='app-bold'>
										{fattura.DettaglioContratto.Altro.NucleiSecondeCase.Valore}
									</p>
								</div>
							)}
							{fattura.DettaglioContratto.Altro.AllacciamentoFognature && (
								<div>
									{
										fattura.DettaglioContratto.Altro.AllacciamentoFognature
											.Etichetta
									}
									<p className='app-bold'>
										{
											fattura.DettaglioContratto.Altro.AllacciamentoFognature
												.Valore
										}
									</p>
								</div>
							)}
							{fattura.DettaglioContratto.Altro.Componenti &&
								fattura.DettaglioContratto.Altro.Componenti.length > 0 && (
									<div>
										{fattura.DettaglioContratto.Altro.Componenti.map(
											(item, index) => {
												return (
													<div key={index}>
														{item.Etichetta}
														<p className='app-bold'>{item.Valore}</p>
													</div>
												);
											}
										)}
									</div>
								)}
							{fattura.DettaglioContratto.Altro.ImpiantoDepurazione && (
								<div>
									{
										fattura.DettaglioContratto.Altro.ImpiantoDepurazione
											.Etichetta
									}
									<p className='app-bold'>
										{
											fattura.DettaglioContratto.Altro.ImpiantoDepurazione
												.Valore
										}
									</p>
								</div>
							)}
							{fattura.DettaglioContratto.Altro.DepositoCauzionale && (
								<div>
									{
										fattura.DettaglioContratto.Altro.DepositoCauzionale
											.Etichetta
									}
									<p className='app-bold'>
										{fattura.DettaglioContratto.Altro.DepositoCauzionale.Valore}
									</p>
								</div>
							)}
							{fattura.DettaglioContratto.Altro.AdesioneFondoPerdite && (
								<div>
									{
										fattura.DettaglioContratto.Altro.AdesioneFondoPerdite
											.Etichetta
									}
									<p className='app-bold'>
										{
											fattura.DettaglioContratto.Altro.AdesioneFondoPerdite
												.Valore
										}
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='col-12 col-md-6'>
					<div className='m2 ms-md-1 mt-md-5 me-md-5 mb-md-5'>
						<MeterDetail
							matricolaContatore={
								fattura.DettaglioContratto.Contatore.MatricolaContatore
							}
							ultimaLettura={fattura.DettaglioContratto.Contatore.UltimaLettura}
							periodoLettura={
								fattura.DettaglioContratto.Contatore.PeriodoLettura
							}
							documentoN={fattura.DocumentoN}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Invoice;
